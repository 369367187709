import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Slider, { Settings } from 'react-slick'
import { getDistance } from 'geolib'

import { Icons } from '@/assets'
import { Promo as PromoItem, useStoreActions, useStoreState } from '@/stores'

type CurrentLocation = {
  latitude: number
  longitude: number
}

interface Props {
  currentLocation: CurrentLocation
}

export const Promo = ({ currentLocation }: Props) => {
  const navigate = useNavigate()

  const { data } = useStoreState(state => state.promo)
  const { fetch } = useStoreActions(action => action.promo)

  useEffect(() => {
    fetch({
      page: 1,
      limit: 100,
      status: 'running'
    })
  }, [fetch])

  const calculateDistance = (from: PromoItem['store'], to: CurrentLocation) => {
    if (!to) return 0
    const dis = getDistance(
      { latitude: from.latitude, longitude: from.longitude },
      { latitude: to.latitude, longitude: to.longitude }
    )
    return dis / 1000
  }

  const { latitude, longitude } = currentLocation
  if (latitude !== 0 && longitude !== 0) data.sort((a, b) => (a.store?.latitude && a.store?.longitude) ? calculateDistance(a.store, { latitude, longitude }) : a.id - (b.store?.latitude && b.store?.longitude) ? calculateDistance(b.store, { latitude, longitude }) : b.id)

  const settings: Settings = {
    dots: false,
    infinite: data?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false
  }

  const Image = ({ item }: { item: PromoItem }) => {
    const [error, setError] = useState<boolean>(false)

    if (error || !item?.photos?.[0])
      return (
        <div
          className="flex justify-center items-center aspect-square bg-[#f5f5f5] animate-fade"
        >
          <img
            className="h-10 opacity-20"
            src={Icons.image_break}
            alt="icon"
          />
        </div>
      )

    return (
      <div
        className="aspect-square bg-center bg-cover"
        style={{ backgroundImage: `url(${item?.photos?.[0]})` }}
      >
        <img
          className="hidden"
          src={item?.photos?.[0]}
          onError={() => setError(true)}
          alt="outlet"
        />
      </div>
    )
  }

  return (
    <div className="bg-white/50 border border-black/20 rounded-3xl p-[0.4rem] shadow-sm">
      <div className="flex justify-between items-center px-2 py-1 mb-1">
        <div className="flex items-center gap-1.5">
          <div className="flex justify-center items-center bg-[#fff6f6] bg-opacity-50 border border-[#ffe7e7] rounded-lg p-1 aspect-square">
            <img
              className="inline-block h-5 w-5"
              src={Icons.promo}
              alt="icon"
            />
          </div>
          <div className="font-outfit font-bold text-sm text-black/70">
            PROMOTIONS
          </div>
        </div>
        <div
          className="flex items-center gap-1.5 cursor-pointer"
          onClick={() => navigate('/promotion')}
        >
          <div className="font-outfit font-bold text-sm text-primary">
            See All
          </div>
          <img
            className="block h-4"
            src={Icons.right_arrow}
            alt="icon"
          />
        </div>
      </div>
      {data?.length > 0 &&
        <div className="rounded-[1.2rem] border border-black/20 overflow-hidden">
          <Slider {...settings}>
            {data.map((item, index) =>
              <div
                key={index}
                className="!block cursor-pointer"
                onClick={() => navigate(`/promotion/${item?.id}/detail`, { state: { item } })}
              >
                <Image item={item} />
              </div>
            )}
          </Slider>
        </div>
      }
    </div>
  )
}
